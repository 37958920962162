import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

// import "channels";
import "jquery";
window.jQuery = window.$ = $;

// toastr
import toastr from 'toastr';

window.toastr = toastr;
toastr.options = {
    'closeButton': true,
    'positionClass': 'toast-bottom-right',
    'timeOut': '3000'
};

// IEアラート
import { alertModal } from './shared/alert_modal'
import SelectSearchable from './shared/select_searchable'

$(document).ready(function() {
    CommonFunctions.showToastErrorWithScreens();
    SelectSearchable.init();
});

const CommonFunctions = {
    showIEError(path) {
        var ua = window.navigator.userAgent;
        var msie =/Edge\/|Trident\/|MSIE /.test(ua);
        // Return if not IE browser
        if (!msie) { return; }
        if(window.location.href.indexOf("/partner/fields") >= 0 || window.location.href.indexOf("/prime/fields") >= 0) {
            alertModal('Internet Explorerは非対応です。\n Chrome、Edge、Firefox、Safari\nのいずれかで利用してください。', path)
        }
    },
    showToastErrorWithScreens: function() {
        var currentScreen = window.location.pathname.split("/")[1];
        if (currentScreen == "prime" || currentScreen == "partner") {
            this.showIEError("/" + currentScreen + "/logout");
        }
    }
}

export default CommonFunctions;
